/* import __COLOCATED_TEMPLATE__ from './bespoke-segmented-control-button.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    value: string;
    onSelectionChange: (value: boolean | string) => void;
    selectedValue: boolean | string;
    label: string;
    icon: InterfaceIconName;
  };
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const BespokeSegmentedControlButton = templateOnlyComponent<Signature>();
export default BespokeSegmentedControlButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Standalone::BespokeSegmentedControlButton': typeof BespokeSegmentedControlButton;
    'onboarding/guide-library/standalone/bespoke-segmented-control-button': typeof BespokeSegmentedControlButton;
  }
}
