/* import __COLOCATED_TEMPLATE__ from './quick-invite-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type QuoteService from 'embercom/services/quote-service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
import {
  FULL_ACCESS_ROLE_NAME,
  NO_ACCESS_ROLE_NAME,
  VIEW_ONLY_ROLE_NAME,
} from 'embercom/components/table-cells/teammate-list/standalone-access-level-selector';
import { TeammatesInviter } from 'embercom/lib/teammates-inviter';
import { getOwner } from '@ember/application';
import type CopilotDataService from 'embercom/services/copilot-data-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Signature {
  Args: {
    isWorkEmail: boolean;
    closeModal: () => void;
    isStandalone?: boolean;
    roles?: any[];
  };
  Element: HTMLDivElement;
}

export default class QuickInviteModal extends Component<Signature> {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare permissionsService: $TSFixMe;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare copilotDataService: CopilotDataService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showModal = false;
  @tracked selectedStandaloneRoleId = this.fullAccessStandaloneRole?.id;
  @tracked standaloneCopilotAccess = false;
  teammatesInviter: TeammatesInviter;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadServices();
    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'viewed',
      locale: 'quick-invite-modal',
      success: true,
    });

    this.teammatesInviter = new TeammatesInviter(getOwner(this));
  }

  async loadServices() {
    await this.customerService.ensureDataIsLoaded.perform();
    if (this.customerService.activePlans?.length) {
      await taskFor(this.quoteService.getQuotes).perform([
        {
          ...DEFAULT_REQUEST_PARAMS,
          planIds: [this.customerService.activePlans[0].id],
          source: 'library-home',
        },
      ]);
    }
  }

  get app() {
    return this.appService.app;
  }

  get copilotSeatPrice() {
    let priceInCents = this.copilotDataService.copilotData.price;

    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }

  get sortedStandaloneRoles() {
    if (!this.args.roles) {
      return [];
    }

    return this.standaloneRoles.sort((a: any, b: any) => {
      if (a.role.name === FULL_ACCESS_ROLE_NAME || b.role.name === NO_ACCESS_ROLE_NAME) {
        return -1;
      } else if (a.role.name === NO_ACCESS_ROLE_NAME || b.role.name === FULL_ACCESS_ROLE_NAME) {
        return 1;
      }

      return 0;
    });
  }

  private get standaloneRoles() {
    if (!this.args.roles) {
      return [];
    }

    let roles = this.args.roles;

    if (!this.standaloneCopilotAccess) {
      roles = roles.filter((role: any) => role.id !== this.noAccessRole?.id);
    }

    return roles.map((role: any) => {
      return {
        role,
        icon: this.iconForStandaloneRole(role),
        description: this.descriptionForStandaloneRole(role),
      };
    });
  }

  private iconForStandaloneRole(role: any) {
    if (role.name === NO_ACCESS_ROLE_NAME) {
      return 'unsubscribed';
    } else if (role.name === VIEW_ONLY_ROLE_NAME) {
      return 'eye';
    } else {
      return 'list';
    }
  }

  private descriptionForStandaloneRole(role: any) {
    if (role.name === NO_ACCESS_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.no-access-description',
      );
    } else if (role.name === VIEW_ONLY_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.view-only-description',
      );
    } else if (role.name === FULL_ACCESS_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.full-access-description',
      );
    }

    return '';
  }

  private get fullAccessStandaloneRole() {
    return this.args.roles?.find((role: any) => role.name === FULL_ACCESS_ROLE_NAME);
  }

  get inTrial() {
    return (
      this.customerService.customer?.inTrial ||
      this.customerService.customer?.currentEarlyStageCustomer
    );
  }

  get title() {
    if (this.args.isStandalone) {
      return this.intl.t(
        'onboarding.home.steps.invite-teammates.quick-invite-modal.standalone-title',
      );
    }
    return this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.title');
  }

  get formattedPrice() {
    if (!this.customerService.activePlanIds.length) {
      return '';
    }
    let plan = this.customerService.activePlanIds[0];
    let quote = this.quoteService.getQuoteById([plan]);
    let price = quote?.fullSeatPrice(plan, Metric.core_seat_count);

    if (!price) {
      return '';
    }

    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get priceString() {
    return this.intl.t(
      'onboarding.home.steps.invite-teammates.quick-invite-modal.permissions-and-pricing-explanation',
      {
        inTrial: this.inTrial,
        price: this.formattedPrice,
        htmlSafe: true,
      },
    );
  }

  @action onStandaloneRoleChange(value: string) {
    this.selectedStandaloneRoleId = value;
  }

  @action onStandaloneCopilotAccessChange(value: boolean) {
    if (this.selectedStandaloneRoleId === this.noAccessRole?.id) {
      this.onStandaloneRoleChange(this.fullAccessStandaloneRole?.id);
    }

    this.standaloneCopilotAccess = value;
  }

  private get noAccessRole() {
    return this.args.roles?.find((role: any) => role.name === NO_ACCESS_ROLE_NAME);
  }

  @action async performInvite() {
    let success: boolean;
    try {
      if (this.numberOfToBeChargedCopilotSeats > 0) {
        let confirmed = await this.confirmAddCopilotSeat();

        if (!confirmed) {
          return;
        }
      }

      await this.teammatesInviter.performInvite(
        this.selectedStandaloneRoleId,
        this.standaloneCopilotAccess,
      );
      success = true;

      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.success-banner', {
          numberOfTeammatesInvited: this.numberOfTeammatesInvited,
        }),
      );
      this.args.closeModal();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('onboarding.home.developer-invite.error'));
      success = false;
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'clicked',
      locale: 'quick-invite-modal',
      success,
    });
  }

  private async confirmAddCopilotSeat() {
    let confirmOptions = {
      title: this.intl.t('settings.copilot.standalone-modal-title'),
      primaryButtonType: 'primary',
      confirmButtonText: this.intl.t('settings.copilot.confirm'),
      cancelButtonText: this.intl.t('settings.copilot.cancel'),
      body: this.intl.t('settings.copilot.standalone-modal-body', {
        price: this.totalCopilotSeatPrice,
        numberOfTeammates: this.numberOfTeammatesInvited,
      }),
    };

    return await this.intercomConfirmService.confirm(confirmOptions);
  }

  private get totalCopilotSeatPrice() {
    let priceInCents =
      this.copilotDataService.copilotData.price * this.numberOfToBeChargedCopilotSeats;

    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }

  private get numberOfToBeChargedCopilotSeats() {
    if (!this.standaloneCopilotAccess) {
      return 0;
    }

    if (this.hasContractedSeatsLeft) {
      return this.numberOfTeammatesInvited - this.contractedSeatsRemaining;
    }

    return this.numberOfTeammatesInvited;
  }

  get numberOfTeammatesInvited() {
    return this.teammatesInviter.validEmails.length;
  }

  private get hasContractedSeatsLeft() {
    return (
      this.contractedSeatsRemaining > 0 &&
      (this.appService.app.isSalesforceContracted ||
        this.customerService.isP5SelfServeAnnualCustomer)
    );
  }

  private get contractedSeatsRemaining() {
    let { contractedLimit, used } = this.copilotDataService.copilotData.seatUsages;

    return contractedLimit - used;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::QuickInviteModal': typeof QuickInviteModal;
  }
}
