/* import __COLOCATED_TEMPLATE__ from './bespoke-segmented-control.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';
import { type Invokable } from '@glint/template/-private/integration';

interface Signature {
  Args: {
    onSelectionChange: (value: boolean | string) => void;
    selectedValue: boolean | string;
  };
  Element: HTMLSpanElement;
  Blocks: {
    default: [
      {
        button: Invokable<any>;
      },
    ];
  };
}

const BespokeSegmentedControl = templateOnlyComponent<Signature>();
export default BespokeSegmentedControl;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Standalone::BespokeSegmentedControl': typeof BespokeSegmentedControl;
    'onboarding/guide-library/standalone/bespoke-segmented-control': typeof BespokeSegmentedControl;
  }
}
